import { Card, Col, Form, InputNumber, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetMaintenance } from "../hooks/use-get-maintenance";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import VuiSelect from "../../../components/select";
import { useCallback, useEffect } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormMaintenance } from "../hooks/use-form-maintenance";
import { MaintenanceFormData, MaintenancePayloadType } from "./interface";
import { convertToSelectValue } from "../../../components/select/function";
import UserRepository from "../../../repositories/user-repository";
import CustomerRepository from "../../../repositories/customer-repository";
import ProjectRepository from "../../../repositories/project-repository";
import VuiDatePicker from "../../../components/vui-date-picker";
import moment from "moment";

const MaintenanceFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const title = id
    ? t("pages.maintenance.edit.title")
    : t("pages.maintenance.add.title");
  const { loadDetailData, isOnFetchingDetailData, detailData } =
    useGetMaintenance();
  const { submitLoading, onSubmit } = useFormMaintenance();

  const [form] = Form.useForm();

  const selectedCustomer = Form.useWatch("customer_id", form);

  const selectedProject = Form.useWatch("project_id", form);

  const onFinish = useCallback(
    async (data: MaintenanceFormData) => {
      const normalizeData: MaintenancePayloadType = {
        customer_id: data.customer_id.value as number,
        project_manager_id: data.project_manager_id.value as number,
        project_id: data.project_id.value as number,
        date: moment(data.date).format("YYYY-MM-DD"),
        duration: data.duration,
        description: data.description,
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  useEffect(() => {
    if (id) {
      (async () => {
        await loadDetailData(id, {
          with: ["customer", "projectManager", "project"],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && detailData) {
      form.setFieldsValue({
        ...detailData,
        date: moment(detailData.date),
        customer_id: convertToSelectValue(detailData.customer),
        project_manager_id: convertToSelectValue(detailData.project_manager),
        project_id: {
          label: detailData.project.code,
          value: detailData.project.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  return (
    <>
      <VuiPageTitle
        title={title}
        onBackLink={id ? `/maintenance/${id}` : "/maintenance"}
      />

      <Spin size="large" spinning={isOnFetchingDetailData}>
        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col md={8} xs={24}>
              <Card title={t("common.text.generalInformation")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Form.Item
                      name="customer_id"
                      label={t("common.form.client.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.client.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={CustomerRepository}
                        placeholder={t("common.form.client.placeholder")}
                        onChange={() =>
                          form.resetFields(["project_id", "project_manager_id"])
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="description"
                      label={t("common.form.description.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.description.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.description.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={16} xs={24}>
              <Card title={t("common.text.projectInformation")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="project_id"
                      label={t("common.form.idProject.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.idProject.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={ProjectRepository}
                        placeholder={t("common.form.idProject.placeholder")}
                        repositoryParams={{
                          customer: selectedCustomer?.value,
                        }}
                        labelKey="code"
                        onChange={() => {
                          form.resetFields(["project_manager_id"]);
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="project_manager_id"
                      label={t("common.form.projectManager.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.projectManager.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={UserRepository}
                        repositoryParams={{
                          project: selectedProject?.value,
                        }}
                        placeholder={t(
                          "common.form.projectManager.placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="date"
                      label={t("common.form.maintenanceDate.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.maintenanceDate.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiDatePicker />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="duration"
                      label={t("common.form.durationMonth.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.durationMonth.label"),
                          }),
                        },
                      ]}
                    >
                      <InputNumber
                        size="large"
                        placeholder={t("common.form.durationMonth.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={24}>
              <VuiFormActionButton
                cancelBtnLink={id ? `/maintenance/${id}` : "/maintenance"}
                isLoading={submitLoading}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default MaintenanceFormModule;
