import { api, createCancelTokenHandler } from "../utils/services";
import { ENV } from "../constants";

const WidgetRepository = {
  requestInvoice() {
    return api.get(`${ENV.getApiEndPoint()}/api/widget/invoice-requested`, {
      cancelToken:
        cancelTokenHandlerObject[
          this.requestInvoice.name
        ].handleRequestCancellation().token,
    });
  },
  dueInvoice() {
    return api.get(`${ENV.getApiEndPoint()}/api/widget/invoice-due`, {
      cancelToken:
        cancelTokenHandlerObject[
          this.dueInvoice.name
        ].handleRequestCancellation().token,
    });
  },
  maintenanceAlmostExpired() {
    return api.get(
      `${ENV.getApiEndPoint()}/api/widget/maintenance-almost-expired`,
      {
        cancelToken:
          cancelTokenHandlerObject[
            this.maintenanceAlmostExpired.name
          ].handleRequestCancellation().token,
      }
    );
  },
  domainAlmostExpired() {
    return api.get(`${ENV.getApiEndPoint()}/api/widget/domain-almost-expired`, {
      cancelToken:
        cancelTokenHandlerObject[
          this.domainAlmostExpired.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(WidgetRepository);

export default WidgetRepository;
