import "./_style.less";
import { PropsWithChildren } from "react";
import Typography from "antd/es/typography";
import Space from "antd/es/space";
import { VuiPageTitleProps } from "./interface";
import { LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Title } = Typography;

const VuiPageTitle = ({
  title,
  children,
  onBackLink,
  rightTitleContent,
}: PropsWithChildren<VuiPageTitleProps>) => {
  return (
    <div className="vui-page-title">
      <div className="left-section">
        {onBackLink && (
          <Link to={onBackLink}>
            <LeftOutlined />
          </Link>
        )}

        <Title
          level={4}
          style={{
            fontWeight: 500,
            marginBottom: 0,
          }}
        >
          {title}
        </Title>
        <Space size={10} wrap={true} className="btn-wrapper">
          {rightTitleContent}
        </Space>
      </div>

      <div className="right-section">
        <Space size={10} wrap={true} className="btn-wrapper">
          {children}
        </Space>
      </div>
    </div>
  );
};

export default VuiPageTitle;
