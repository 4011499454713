import { Card, Col, Form, InputNumber, Row, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetGwProduct } from "../hooks/use-get-gw-product";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import { useCallback, useEffect } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormGwProduct } from "../hooks/use-form-gw-product";
import { GwProductFormData, GwProductPayloadType } from "./interface";
import { inputNumberThousandSeparator } from "../../../utils/helpers";

const GwProductFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const title = id
    ? t("pages.gwProduct.detail.title")
    : t("pages.gwProduct.add.title");
  const { loadGwProductData, isOnFetchingGwProductData, gwProductData } =
    useGetGwProduct();
  const { submitLoading, onSubmit } = useFormGwProduct();

  const [form] = Form.useForm<GwProductFormData>();

  const onFinish = useCallback(
    async (data: GwProductFormData) => {
      const normalizeData: GwProductPayloadType = {
        ...data,
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  useEffect(() => {
    if (id) {
      (async () => {
        await loadGwProductData(id);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && gwProductData) {
      form.setFieldsValue({
        ...gwProductData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gwProductData]);

  return (
    <>
      <VuiPageTitle title={title} onBackLink="/other-list/gw-product" />

      <Spin size="large" spinning={isOnFetchingGwProductData}>
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{
            is_active: true,
          }}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col md={18} xs={24}>
              <Card title={t("common.text.information")}>
                <Row gutter={[16, 16]}>
                  <Col xs={12} md={12}>
                    <Form.Item
                      name="sku_id"
                      label={t("common.form.skuId.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.skuId.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.skuId.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12}>
                    <Form.Item
                      name="sku_name"
                      label={t("common.form.skuName.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.skuName.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.skuName.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24}>
                    <Form.Item
                      name="price"
                      label={`${t("common.form.price.label")} (Rp)`}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.price.label"),
                          }),
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder={t("common.form.price.placeholder")}
                        formatter={inputNumberThousandSeparator.formatter}
                        parser={inputNumberThousandSeparator.parser}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24}>
                    <Form.Item
                      name="description"
                      label={t("common.form.description.label")}
                    >
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder={t("common.form.description.placeholder")}
                        options={[]}
                        open={false}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={24} xs={24}>
              <VuiFormActionButton
                cancelBtnLink="/other-list/gw-product"
                isLoading={submitLoading}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default GwProductFormModule;
