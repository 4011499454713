import { Button, Form, Modal, Space } from "antd";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import VuiUploadImage from "../../../../components/upload-image";
import { InvoiceUploadFormData } from "../../detail/interface";

export type ModalUploadProps = {
  show: boolean;
  onClose: () => void;
  submitLoading: boolean;
  onSubmit: (formData: InvoiceUploadFormData) => void;
  title: string | ReactNode;
};

const ModalUpload = ({
  show,
  onClose,
  submitLoading,
  onSubmit,
  title,
}: ModalUploadProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  return (
    <Modal
      title={title}
      centered
      visible={show}
      onCancel={onClose}
      footer={[
        <Button key="cancel-button" onClick={onClose}>
          {t("common.button.cancel")}
        </Button>,
        <Button
          key="submit-button"
          type="primary"
          loading={submitLoading}
          onClick={() => {
            form
              .validateFields()
              .then((values: InvoiceUploadFormData) => {
                onSubmit(values);
              })
              .catch((info: string) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          {t("common.button.submit")}
        </Button>,
      ]}
    >
      <Form form={form} layout={"vertical"}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form.Item name="document_id">
            <VuiUploadImage accept="application/pdf" />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default ModalUpload;
