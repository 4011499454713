import { api, createCancelTokenHandler } from "../utils/services";
import { ENV } from "../constants";

const ConstantRepository = {
  select: function (params: any = null) {
    return api.get(`${ENV.getApiEndPoint()}/api/select/constant`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(ConstantRepository);

export default ConstantRepository;
