import { Typography } from "antd";
import { useMemo, useState } from "react";
import { Permission } from "../../../../models/permission";
import { SelectedPermission } from "../../form";

const { Text } = Typography;

type BtnSelectAllProps = {
  onClick: (isSelectAll: boolean) => void;
  selectedPermissions: SelectedPermission[];
  scopePermissions: Permission[];
};

const BtnSelectAll = ({
  onClick,
  selectedPermissions,
  scopePermissions,
}: BtnSelectAllProps) => {
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  useMemo(() => {
    const scopePermissionIds = scopePermissions.map((p) => p.id);
    const filtered = selectedPermissions.filter((p) =>
      scopePermissionIds.includes(p.id)
    );
    if (filtered.length === scopePermissionIds.length) {
      setIsSelectAll(scopePermissionIds.length > 0);
    } else {
      setIsSelectAll(false);
    }
  }, [selectedPermissions, selectedPermissions]);

  return (
    <Text
      className="role-select-all"
      onClick={() => onClick(!isSelectAll)}
      strong
    >
      {isSelectAll ? "Deselect" : "Select"} all
    </Text>
  );
};

export default BtnSelectAll;
