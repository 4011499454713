import React from "react";

const GoogleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.978"
    height="20.978"
    viewBox="0 0 20.978 20.978"
    {...props}
  >
    <path
      id="icons8-google"
      d="M14.489,4a10.564,10.564,0,1,0,10.383,9.072.787.787,0,0,0-.779-.681H14.751a.787.787,0,0,0-.787.787v3.147a.787.787,0,0,0,.787.787h4.794a5.694,5.694,0,0,1-5.056,3.147A5.769,5.769,0,1,1,18.3,10.171a.787.787,0,0,0,1.077-.034L21.6,7.913a.787.787,0,0,0-.023-1.135A10.426,10.426,0,0,0,14.489,4Zm0,1.573a8.77,8.77,0,0,1,5.367,1.858L18.69,8.6a7.184,7.184,0,0,0-4.2-1.45A7.338,7.338,0,0,0,8.38,10.432L7.12,9.468A8.9,8.9,0,0,1,14.489,5.573ZM6.355,10.864l1.3.995a7.05,7.05,0,0,0,0,5.261l-1.3.995a8.8,8.8,0,0,1,0-7.25Zm9.183,3.1h7.827c.012.174.04.351.04.524a8.842,8.842,0,0,1-2.182,5.791l-1.228-1.064a7.234,7.234,0,0,0,1.54-2.671.787.787,0,0,0-.755-1.007H15.538ZM8.38,18.546a7.315,7.315,0,0,0,10.52,1.8l1.189,1.031A8.854,8.854,0,0,1,7.12,19.51Z"
      transform="translate(-4 -4)"
      fill="#707070"
    />
  </svg>
);

export default GoogleIcon;
