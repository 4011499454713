import React, { PropsWithChildren } from "react";
import { StatusTagProps } from "./interface";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";

const StatusTag = ({ active }: PropsWithChildren<StatusTagProps>) => {
  const { t } = useTranslation();

  return (
    <Tag color={active ? "success" : "error"}>
      {active ? t("common.text.active") : t("common.text.inactive")}
    </Tag>
  );
};

export default StatusTag;
