import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useBreadcrumb } from "../../../context/breadcrumb";
import { BreadcrumbActionType } from "../../../context/breadcrumb/reducer";
import VuiHelmet from "../../../components/helmet";
import IndustryListModule from "../../../modules/industry/list";
import SideMenuLayout from "../../../layouts/side-menu";
import { generateOtherMenu } from "../../../constants/other-menu";

const IndustryListPage = () => {
  const { t } = useTranslation();
  const { dispatch } = useBreadcrumb();
  const sideMenus = generateOtherMenu(t);

  useEffect(() => {
    dispatch({
      type: BreadcrumbActionType.Update,
      payload: [
        {
          link: "/",
          title: t("common.text.home"),
        },
        {
          link: "/other-list",
          title: t("common.text.otherList"),
          isDisabled: true,
        },
        {
          link: "/industry",
          title: t("common.text.industry"),
          isDisabled: true,
        },
      ],
    });
  }, [dispatch, t]);

  return (
    <>
      <VuiHelmet title={t("pages.industry.list.metaTitle")} />

      <SideMenuLayout menus={sideMenus}>
        <IndustryListModule />
      </SideMenuLayout>
    </>
  );
};

export default IndustryListPage;
