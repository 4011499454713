import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../components/helmet";
import DomainDetailModule from "../../../modules/domain/detail";

const DomainDetailPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.domain.detail.metaTitle")} />

      <DomainDetailModule />
    </>
  );
};

export default DomainDetailPage;
