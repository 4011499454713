import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { ICollection } from "../../../utils/interfaces/collection.interface";
import { handleBackendError } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { IPaginationParams } from "../../../utils/interfaces/pagination-params.interface";
import { IResource } from "../../../utils/interfaces/resource.interface";
import CategoryRepository from "../../../repositories/category-repository";
import { CategoryList } from "../list/interface";
import { Category } from "../../../models/category";

export const useGetCategory = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<CategoryList[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [isOnFetchingData, setIsOnFetchingData] = useState<boolean>(false);

  const [categoryData, setCategoryData] = useState<Category | null>(null);
  const [isOnFetchingCategoryData, setIsOnFetchingCategoryData] =
    useState<boolean>(false);

  const loadData = useCallback(async (params: IPaginationParams) => {
    setIsOnFetchingData(true);

    await CategoryRepository.all(params)
      .then((response: AxiosResponse<ICollection<CategoryList[]>>) => {
        setData(response.data.data);
        setTotalData(response.data.meta.total);
      })
      .catch(() => {})
      .finally(() => {
        setIsOnFetchingData(false);
      });
  }, []);

  const loadCategoryData = useCallback(
    async (id: string, params: any = null) => {
      setIsOnFetchingCategoryData(true);

      await CategoryRepository.show(id, params)
        .then((response: AxiosResponse<IResource<Category>>) => {
          setCategoryData(response.data.data);
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setIsOnFetchingCategoryData(false);
        });
    },
    [t]
  );

  return {
    data,
    totalData,
    loadData,
    isOnFetchingData,
    loadCategoryData,
    isOnFetchingCategoryData,
    categoryData,
  };
};
