import api, { createCancelTokenHandler } from "../utils/services/api.service";
import { ENV } from "../constants";

const ChartOfAccountRepository = {
  select: function (params: any = null) {
    return api.get(`${ENV.getApiEndPoint()}/api/select/chart-of-account`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(ChartOfAccountRepository);

export default ChartOfAccountRepository;
