import api, { createCancelTokenHandler } from "../utils/services/api.service";
import { ENV } from "../constants";

const PermissionRepository = {
  all(params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/permission`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(PermissionRepository);

export default PermissionRepository;
