import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { handleBackendError, openNotification } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LeadRepository from "../../../repositories/lead-repository";
import { IResource } from "../../../utils/interfaces/resource.interface";
import { Lead } from "../../../models/lead";
import { LeadPayloadType } from "../form/interface";
import { ActivityPayloadType } from "../detail/interface";

export const useFormLead = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [activityLoading, setActivityLoading] = useState<boolean>(false);
  const [activityDeleteLoading, setActivityDeleteLoading] =
    useState<boolean>(false);

  const onDelete = useCallback(
    async (id: number | string) => {
      setDeleteLoading(true);

      return new Promise((resolve, reject) => {
        LeadRepository.delete(id)
          .then((response: AxiosResponse) => {
            resolve(response);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
            reject(e);
          })
          .finally(() => {
            setDeleteLoading(false);
          });
      });
    },
    [t]
  );

  const onSubmit = useCallback(
    async (data: LeadPayloadType, id: string | null = null) => {
      setSubmitLoading(true);

      await (id ? LeadRepository.update(id, data) : LeadRepository.create(data))
        .then((response: AxiosResponse<IResource<Lead>>) => {
          navigate(id ? `/lead/${id}` : "/lead");
          openNotification(
            "success",
            t(`notification.success.${id ? "updateItem" : "createItem"}`, {
              item: t("common.text.lead"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, navigate]
  );

  const onActivity = useCallback(
    async (id: string, data: ActivityPayloadType) => {
      setActivityLoading(true);

      await LeadRepository.activity(id, data)
        .then(() => {
          openNotification(
            "success",
            t(`notification.success.createItem`, {
              item: t("common.text.activity"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setActivityLoading(false);
        });
    },
    [t]
  );

  const onDeleteActivity = useCallback(
    async (id: string, activityId: string) => {
      setActivityDeleteLoading(true);

      await LeadRepository.deleteActivity(id, activityId)
        .then(() => {
          openNotification(
            "success",
            t(`notification.success.deleteItem`, {
              item: t("common.text.activity"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setActivityDeleteLoading(false);
        });
    },
    [t]
  );

  return {
    onSubmit,
    submitLoading,
    deleteLoading,
    onDelete,
    onActivity,
    activityLoading,
    onDeleteActivity,
    activityDeleteLoading,
  };
};
