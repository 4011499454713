import { Col, Input, Row, Table, Tabs } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import VuiPageTitle from "../../../components/page-title";
import { useTranslation } from "react-i18next";
import VuiButton from "../../../components/button";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table/interface";
import { UserList } from "../../user/list/interface";
import VuiTableActionButton from "../../../components/table-action-button";
import { useGetUser } from "../../user/hooks/use-get-user";
import VuiAccessible from "../../../components/accessible";
import { IPaginationParams } from "../../../utils/interfaces/pagination-params.interface";
import { overrideTableSortOrder } from "../../../utils/helpers";
import useDebounce from "../../../hooks/use-debounce";
import { useGetRole } from "../../role/hooks/use-get-role";
import TabPane from "antd/lib/tabs/TabPane";
import { RoleList } from "../../role/list/interface";
import { useAuth } from "../../../context/auth";
import { DEFAULT_PAGE_SIZE } from "../../../constants";

type UserRoleTabType = "user" | "role";

const qs = require("qs-lite");

const UserRoleListModule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, loadData, isOnFetchingData, totalData } = useGetUser();
  const { state } = useAuth();
  const {
    data: roleData,
    loadData: loadRoleData,
    isOnFetchingData: isOnFetchingRoleData,
    totalData: totalRoleData,
  } = useGetRole();
  const [hasInitialize, setHasInitialize] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 250);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tableQuery, setTableQuery] = useState<IPaginationParams>({
    page: Number(searchParams.get("page")) || 1,
    per_page: Number(searchParams.get("perPage")) || DEFAULT_PAGE_SIZE,
    sorted_by: searchParams.get("sortedBy"),
    order_by: searchParams.get("orderBy") as string,
  });
  const [selectedTab, setSelectedTab] = useState<UserRoleTabType>(
    (searchParams.get("tab") as UserRoleTabType) || "user"
  );

  const handleChangeSearchParams = useCallback(async () => {
    const params = {} as IPaginationParams;

    Object.assign(params, {
      page: tableQuery.page,
      perPage: tableQuery.per_page,
      tab: selectedTab,
    });

    if (debouncedSearch) {
      Object.assign(params, {
        search: debouncedSearch,
      });
    }

    if (tableQuery.order_by) {
      Object.assign(params, {
        orderBy: tableQuery.order_by,
      });
    }

    if (tableQuery.sorted_by) {
      Object.assign(params, {
        sortedBy: tableQuery.sorted_by,
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("/access");
    }

    const tableParams: IPaginationParams = {
      page: params.page,
      per_page: tableQuery.per_page,
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      ...(tableQuery.order_by ? { order_by: tableQuery.order_by } : {}),
      ...(tableQuery.sorted_by
        ? { sorted_by: overrideTableSortOrder(tableQuery.sorted_by) }
        : {}),
    };

    if (selectedTab === "user") {
      await loadData(tableParams);
    } else {
      await loadRoleData(tableParams);
    }

    setHasInitialize(true);
  }, [
    tableQuery,
    debouncedSearch,
    loadData,
    navigate,
    loadRoleData,
    searchParams,
    setSearchParams,
    selectedTab,
  ]);

  const userColumns: ColumnsType<UserList> = [
    {
      title: t("common.text.name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      defaultSortOrder: tableQuery.order_by === "name" && tableQuery.sorted_by,
    },
    {
      title: t("common.text.role"),
      dataIndex: "role_name",
      key: "role_name",
      sorter: true,
      defaultSortOrder:
        tableQuery.order_by === "role_name" && tableQuery.sorted_by,
    },
    {
      title: t("common.text.action"),
      key: "id",
      className: "table-action",
      fixed: "right",
      width: 110,
      render: (text, record) => {
        return (
          <VuiTableActionButton
            detailLink={`/user/${record.id}`}
            detailPermissionName="user.show"
          />
        );
      },
    },
  ];

  const roleColumns: ColumnsType<RoleList> = [
    {
      title: t("common.text.name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      defaultSortOrder: tableQuery.order_by === "name" && tableQuery.sorted_by,
    },
    {
      title: t("common.text.action"),
      key: "id",
      className: "table-action",
      fixed: "right",
      width: 110,
      render: (text, record) => {
        return (
          <VuiTableActionButton
            detailLink={`/role/${record.id}`}
            detailPermissionName="role.show"
          />
        );
      },
    },
  ];

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any, extra: any) => {
      const params: IPaginationParams = {
        page: pagination.current,
        per_page: pagination.pageSize,
      };

      if (sorter.order) {
        Object.assign(params, {
          sorted_by: sorter.order,
          order_by: sorter.field,
        });
      } else {
        Object.assign(params, {
          sorted_by: null,
          order_by: null,
        });
      }

      setTableQuery(params);
    },
    []
  );

  const hasUserListPermission = useMemo(() => {
    return !!state.user?.permissions.includes("user.index");
  }, [state.user?.permissions]);

  const hasRoleListPermission = useMemo(() => {
    return !!state.user?.permissions.includes("role.index");
  }, [state.user?.permissions]);

  const handleTabChange = (key: string) => {
    setSelectedTab(key as UserRoleTabType);

    setTableQuery((prevState) => ({
      ...prevState,
      page: 1,
      tab: key,
    }));
  };

  useEffect(() => {
    (async () => {
      await handleChangeSearchParams();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableQuery]);

  useEffect(() => {
    if (hasInitialize) {
      setTableQuery((prevState) => ({
        ...prevState,
        search: debouncedSearch,
        page: 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <>
      <VuiPageTitle title={t("pages.userAndRole.list.title")}>
        {selectedTab === "user" && (
          <VuiAccessible access="user.store">
            <Link to="/user/add">
              <VuiButton
                title={t("common.button.addItem", {
                  item: t("common.text.user"),
                })}
                buttonProps={{
                  icon: <PlusOutlined />,
                  size: "middle",
                }}
              />
            </Link>
          </VuiAccessible>
        )}

        {selectedTab === "role" && (
          <VuiAccessible access="role.store">
            <Link to={"/role/add"}>
              <VuiButton
                title={t("common.button.addItem", {
                  item: t("common.text.role"),
                })}
                buttonProps={{
                  icon: <PlusOutlined />,
                  size: "middle",
                }}
              />
            </Link>
          </VuiAccessible>
        )}
      </VuiPageTitle>

      <Row gutter={[10, 10]} className="mb16">
        <Col className="gutter-row" xs={24} md={8}>
          <Input
            allowClear
            placeholder={t("common.form.search.placeholder")}
            prefix={<SearchOutlined />}
            value={search}
            size="middle"
            onChange={(value) => {
              setSearch(value.target.value);
            }}
          />
        </Col>
      </Row>

      <Tabs defaultActiveKey={selectedTab} onChange={handleTabChange}>
        {hasUserListPermission && (
          <TabPane tab="User" key="user">
            <Table
              rowKey={"id"}
              columns={userColumns}
              dataSource={data}
              loading={isOnFetchingData}
              onChange={handleTableChange}
              pagination={{
                current: tableQuery.page,
                showSizeChanger: true,
                pageSize: tableQuery.per_page,
                total: totalData,
              }}
              size="small"
            />
          </TabPane>
        )}

        {hasRoleListPermission && (
          <TabPane tab="Role" key="role">
            <Table
              rowKey={"id"}
              columns={roleColumns}
              dataSource={roleData}
              loading={isOnFetchingRoleData}
              onChange={handleTableChange}
              pagination={{
                current: tableQuery.page,
                showSizeChanger: true,
                pageSize: tableQuery.per_page,
                total: totalRoleData,
              }}
              size="small"
            />
          </TabPane>
        )}
      </Tabs>
    </>
  );
};

export default UserRoleListModule;
