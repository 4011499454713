import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { handleBackendError, openNotification } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IResource } from "../../../utils/interfaces/resource.interface";
import { Product } from "../../../models/product";
import { GwProductPayloadType } from "../form/interface";
import GwProductRepository from "../../../repositories/gw-product-repository";

export const useFormGwProduct = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (data: GwProductPayloadType, id: string | null = null) => {
      setSubmitLoading(true);

      await (id
        ? GwProductRepository.update(id, data)
        : GwProductRepository.create(data)
      )
        .then((response: AxiosResponse<IResource<Product>>) => {
          navigate("/other-list/gw-product");
          openNotification(
            "success",
            t(`notification.success.${id ? "updateItem" : "createItem"}`, {
              item: t("common.text.gwProduct"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, navigate]
  );

  return {
    onSubmit,
    submitLoading,
  };
};
