import { Card, Col, Form, Radio, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProjectType } from "../hooks/use-get-project-type";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import { useCallback, useEffect, useState } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormProjectType } from "../hooks/use-form-project-type";
import { ProjectTypeFormData, ProjectTypePayloadType } from "./interface";
import { openNotification } from "../../../utils/helpers";
import VuiAccessible from "../../../components/accessible";
import VuiModalConfirmation from "../../../components/modal-confirmation";

const { TextArea } = Input;
const { Text } = Typography;

const ProjectTypeFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = id
    ? t("pages.projectType.detail.title")
    : t("pages.projectType.add.title");
  const { loadProjectTypeData, isOnFetchingProjectTypeData, projectTypeData } =
    useGetProjectType();
  const { submitLoading, onSubmit, onDelete, deleteLoading } =
    useFormProjectType();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [form] = Form.useForm<ProjectTypeFormData>();

  const onFinish = useCallback(
    async (data: ProjectTypeFormData) => {
      const normalizeData: ProjectTypePayloadType = {
        ...data,
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  const handleCloseModalDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (id) {
      await onDelete(id).then(() => {
        setShowDeleteModal(false);
        openNotification(
          "success",
          t("notification.success.deleteItem", {
            item: t("common.text.projectType"),
          })
        );
        navigate(`/other-list/project-type`);
      });
    }
  }, [id, navigate, onDelete, t]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadProjectTypeData(id);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && projectTypeData) {
      form.setFieldsValue({
        ...projectTypeData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTypeData]);

  return (
    <>
      <VuiPageTitle title={title} onBackLink="/other-list/project-type" />

      <Spin size="large" spinning={isOnFetchingProjectTypeData}>
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{
            is_active: true,
          }}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col md={18} xs={24}>
              <Card title={t("common.text.information")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="code"
                      label={t("common.form.code.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.code.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.code.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="name"
                      label={t("common.form.projectTypeName.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.projectTypeName.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t(
                          "common.form.projectTypeName.placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={6} xs={24}>
              <Card title={t("common.text.status")}>
                <Form.Item name="is_active">
                  <Radio.Group>
                    <Radio value={true}>Active</Radio>
                    <Radio value={false}>Not Active</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>
            </Col>

            <Col md={24} xs={24}>
              <VuiFormActionButton
                cancelBtnLink="/other-list/project-type"
                isLoading={submitLoading}
              />
            </Col>

            {id && (
              <VuiAccessible access="project-type.destroy">
                <Col xs={24}>
                  <Text
                    className="cursor-pointer"
                    onClick={() => setShowDeleteModal(true)}
                    title={t("common.text.delete")}
                    type="secondary"
                  >
                    {t("common.text.delete")}
                  </Text>
                </Col>
              </VuiAccessible>
            )}
          </Row>
        </Form>
      </Spin>

      <VuiModalConfirmation
        show={showDeleteModal}
        isLoading={deleteLoading}
        onSubmit={handleConfirmDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
};

export default ProjectTypeFormModule;
