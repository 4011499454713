import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useBreadcrumb } from "../../../context/breadcrumb";
import { BreadcrumbActionType } from "../../../context/breadcrumb/reducer";
import VuiHelmet from "../../../components/helmet";
import LeadListModule from "../../../modules/lead/list";

const LeadListPage = () => {
  const { t } = useTranslation();
  const { dispatch } = useBreadcrumb();

  useEffect(() => {
    dispatch({
      type: BreadcrumbActionType.Update,
      payload: [
        {
          link: "/",
          title: t("common.text.home"),
        },
        {
          link: "/lead",
          title: t("common.text.lead"),
          isDisabled: true,
        },
      ],
    });
  }, [dispatch, t]);

  return (
    <>
      <VuiHelmet title={t("pages.lead.list.metaTitle")} />

      <LeadListModule />
    </>
  );
};

export default LeadListPage;
