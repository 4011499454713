import { Button, Col, Modal, Row, Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import moment from "moment";
import { LeadActivity } from "../../../../models/lead";
import VuiFormItemView from "../../../../components/form-item-view";
import VuiNumberFormat from "../../../../components/number-format";
import { snakeCaseToTitleCase } from "../../../../utils/helpers";

export type ModalActivityProps = {
  show: boolean;
  data?: LeadActivity;
  onClose: () => void;
  title: string | ReactNode;
};

const ModalDetailActivity = ({
  show,
  onClose,
  title,
  data,
}: ModalActivityProps) => {
  const { t } = useTranslation();

  const participantColumns = [
    {
      title: t("common.form.name.label"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("common.form.email.label"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("common.form.whatsapp.label"),
      dataIndex: "whatsapp",
      key: "whatsapp",
    },
  ];

  return (
    <Modal
      title={title}
      width={"50%"}
      centered
      visible={show}
      onCancel={onClose}
      footer={[
        <Button key="cancel-button" onClick={onClose}>
          {t("common.button.close")}
        </Button>,
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <VuiFormItemView
            label={t("common.form.date.label")}
            value={moment(data?.date).format("DD MMM YYYY | HH:mm")}
          />
        </Col>
        <Col xs={24} md={8}>
          <VuiFormItemView
            label={t("common.form.status.label")}
            value={snakeCaseToTitleCase(data?.status ?? "")}
          />
        </Col>
        <Col xs={24} md={8}>
          <VuiFormItemView
            label={t("common.form.assignee.label")}
            value={data?.user?.name}
          />
        </Col>

        {data?.status === "CONTACTED" && (
          <>
            <Col xs={24} md={8}>
              <VuiFormItemView
                label={t("common.form.contactDate.label")}
                value={moment(data?.contact_date).format("DD MMM YYYY")}
              />
            </Col>
            <Col xs={24} md={16}>
              <VuiFormItemView
                label={t("common.form.contactVia.label")}
                value={data?.contact_via?.map((item) => item.label).join(", ")}
              />
            </Col>
          </>
        )}

        {data?.status === "MEETING_SCHEDULED" && (
          <>
            <Col xs={24} md={8}>
              <VuiFormItemView
                label={t("common.form.meetingDate.label")}
                value={moment(data?.meeting_date).format("DD MMM YYYY | HH:mm")}
              />
            </Col>
            <Col xs={24} md={8}>
              <VuiFormItemView
                label={t("common.form.meetingVia.label")}
                value={data?.meeting_via?.label}
              />
            </Col>
            {data?.meeting_via?.label === "Offline" && (
              <Col xs={24} md={8}>
                <VuiFormItemView
                  label={t("common.form.meetingAddress.label")}
                  value={data?.meeting_address}
                />
              </Col>
            )}
            {data?.meeting_via?.label === "Online" && (
              <Col xs={24} md={8}>
                <VuiFormItemView
                  label={t("common.form.meetingLink.label")}
                  value={data?.meeting_link}
                />
              </Col>
            )}
            <Col xs={24}>
              <Table
                dataSource={data?.meeting_participants}
                columns={participantColumns}
              />
            </Col>
          </>
        )}

        {data?.status === "PROPOSAL_PROGRESS" && (
          <Col xs={24} md={8}>
            <VuiFormItemView
              label={t("common.form.estimateDate.label")}
              value={moment(data?.proposal_estimate_date).format("DD MMM YYYY")}
            />
            <VuiFormItemView
              label={t("common.form.proposalCode.label")}
              value={
                <Tag style={{ color: "#757575" }}>{data?.proposal_code}</Tag>
              }
            />
          </Col>
        )}

        {data?.status === "PROPOSAL_SENT" && (
          <>
            <Col xs={24} md={8}>
              <VuiFormItemView
                label={t("common.form.sentDate.label")}
                value={moment(data?.proposal_sent_date).format("DD MMM YYYY")}
              />
            </Col>
            <Col xs={24} md={8}>
              <VuiFormItemView
                label={t("common.form.sentVia.label")}
                value={data?.proposal_sent_via?.label}
              />
            </Col>
            <Col xs={24} md={8}>
              <VuiFormItemView
                label={t("common.form.amount.label")}
                value={
                  <VuiNumberFormat
                    prefix={"Rp"}
                    value={data?.proposal_amount}
                  />
                }
              />
            </Col>
            <Col xs={24}>
              <a href={data?.proposal_attachment.url} target="_blank">
                {data?.proposal_attachment.name}
              </a>
            </Col>
          </>
        )}

        {data?.note && (
          <Col xs={24}>
            <VuiFormItemView
              label={t("common.form.note.label")}
              value={data?.note}
            />
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default ModalDetailActivity;
