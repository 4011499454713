import { api, createCancelTokenHandler } from "../utils/services";
import { ENV } from "../constants";

const Repository = {
  all: function (params: any = null) {
    return api.get(`${ENV.getApiEndPoint()}/api/media`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${ENV.getApiEndPoint()}/api/media/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${ENV.getApiEndPoint()}/api/media`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
