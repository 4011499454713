import api, { createCancelTokenHandler } from "../utils/services/api.service";
import { ENV } from "../constants";

const InboxRepository = {
  all(params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/inbox`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show(id: string, params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/inbox/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  read: function (
    id: number | string,
    payload: any = null,
    params: any = null
  ) {
    return api.post(`${ENV.getApiEndPoint()}/api/inbox/${id}/read`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.read.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(InboxRepository);

export default InboxRepository;
