import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { ICollection } from "../../../utils/interfaces/collection.interface";
import { handleBackendError } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { IPaginationParams } from "../../../utils/interfaces/pagination-params.interface";
import { IResource } from "../../../utils/interfaces/resource.interface";
import ThirdPartyRepository from "../../../repositories/third-party-repository";
import { ThirdPartyList } from "../list/interface";
import { ThirdParty } from "../../../models/third-party";

export const useGetThirdParty = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<ThirdPartyList[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [isOnFetchingData, setIsOnFetchingData] = useState<boolean>(false);

  const [thirdPartyData, setThirdPartyData] = useState<ThirdParty | null>(null);
  const [isOnFetchingThirdPartyData, setIsOnFetchingThirdPartyData] =
    useState<boolean>(false);

  const loadData = useCallback(async (params: IPaginationParams) => {
    setIsOnFetchingData(true);

    await ThirdPartyRepository.all(params)
      .then((response: AxiosResponse<ICollection<ThirdPartyList[]>>) => {
        setData(response.data.data);
        setTotalData(response.data.meta.total);
      })
      .catch(() => {})
      .finally(() => {
        setIsOnFetchingData(false);
      });
  }, []);

  const loadThirdPartyData = useCallback(
    async (id: string, params: any = null) => {
      setIsOnFetchingThirdPartyData(true);

      await ThirdPartyRepository.show(id, params)
        .then((response: AxiosResponse<IResource<ThirdParty>>) => {
          setThirdPartyData(response.data.data);
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setIsOnFetchingThirdPartyData(false);
        });
    },
    [t]
  );

  return {
    data,
    totalData,
    loadData,
    isOnFetchingData,
    loadThirdPartyData,
    isOnFetchingThirdPartyData,
    thirdPartyData,
  };
};
