import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../components/helmet";
import MaintenanceDetailModule from "../../../modules/maintenance/detail";

const MaintenanceDetailPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.maintenance.detail.metaTitle")} />

      <MaintenanceDetailModule />
    </>
  );
};

export default MaintenanceDetailPage;
