import { Button, Spin } from "antd";
import { VuiButtonProps } from "./interface";

const VuiButton = ({
  isLoading = false,
  buttonProps = {},
  title,
}: VuiButtonProps) => {
  const {
    htmlType = "button",
    type = "primary",
    block = true,
    size = "large",
    ...otherButtonProps
  } = buttonProps;

  return (
    <Spin spinning={isLoading}>
      <Button
        htmlType={htmlType}
        type={type}
        block={block}
        size={size}
        {...otherButtonProps}
      >
        {title}
      </Button>
    </Spin>
  );
};

export default VuiButton;
