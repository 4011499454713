import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { ICollection } from "../../../utils/interfaces/collection.interface";
import { handleBackendError } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { IPaginationParams } from "../../../utils/interfaces/pagination-params.interface";
import { IResource } from "../../../utils/interfaces/resource.interface";
import CredentialRepository from "../../../repositories/credential-repository";
import { CredentialList } from "../list/interface";
import { Credential } from "../../../models/credential";

export const useGetCredential = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<CredentialList[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [isOnFetchingData, setIsOnFetchingData] = useState<boolean>(false);

  const [credentialData, setCredentialData] = useState<Credential | null>(null);
  const [isOnFetchingCredentialData, setIsOnFetchingCredentialData] =
    useState<boolean>(false);

  const loadData = useCallback(async (params: IPaginationParams) => {
    setIsOnFetchingData(true);

    await CredentialRepository.all(params)
      .then((response: AxiosResponse<ICollection<CredentialList[]>>) => {
        setData(response.data.data);
        setTotalData(response.data.meta.total);
      })
      .catch(() => {})
      .finally(() => {
        setIsOnFetchingData(false);
      });
  }, []);

  const loadCredentialData = useCallback(
    async (id: string, params: any = null) => {
      setIsOnFetchingCredentialData(true);

      await CredentialRepository.show(id, params)
        .then((response: AxiosResponse<IResource<Credential>>) => {
          setCredentialData(response.data.data);
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setIsOnFetchingCredentialData(false);
        });
    },
    [t]
  );

  return {
    data,
    totalData,
    loadData,
    isOnFetchingData,
    loadCredentialData,
    isOnFetchingCredentialData,
    credentialData,
  };
};
