import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { handleBackendError, openNotification } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MaintenanceRepository from "../../../repositories/maintenance-repository";
import { IResource } from "../../../utils/interfaces/resource.interface";
import { Maintenance } from "../../../models/maintenance";
import { MaintenancePayloadType } from "../form/interface";
import { ExtendPayloadType } from "../detail/interface";

export const useFormMaintenance = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [extendLoading, setExtendLoading] = useState<boolean>(false);

  const onDelete = useCallback(
    async (id: number | string) => {
      setDeleteLoading(true);

      return new Promise((resolve, reject) => {
        MaintenanceRepository.delete(id)
          .then((response: AxiosResponse) => {
            resolve(response);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
            reject(e);
          })
          .finally(() => {
            setDeleteLoading(false);
          });
      });
    },
    [t]
  );

  const onSubmit = useCallback(
    async (data: MaintenancePayloadType, id: string | null = null) => {
      setSubmitLoading(true);

      await (id
        ? MaintenanceRepository.update(id, data)
        : MaintenanceRepository.create(data)
      )
        .then((response: AxiosResponse<IResource<Maintenance>>) => {
          navigate(id ? `/maintenance/${id}` : "/maintenance");
          openNotification(
            "success",
            t(`notification.success.${id ? "updateItem" : "createItem"}`, {
              item: t("common.text.maintenance"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, navigate]
  );

  const onExtend = useCallback(
    async (id: string, data: ExtendPayloadType) => {
      setExtendLoading(true);

      await MaintenanceRepository.extend(id, data)
        .then(() => {
          openNotification(
            "success",
            t(`notification.success.extendItem`, {
              item: t("common.text.maintenance"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setExtendLoading(false);
        });
    },
    [t]
  );

  return {
    onSubmit,
    submitLoading,
    deleteLoading,
    onDelete,
    onExtend,
    extendLoading,
  };
};
