import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useBreadcrumb } from "../../../context/breadcrumb";
import { BreadcrumbActionType } from "../../../context/breadcrumb/reducer";
import VuiHelmet from "../../../components/helmet";
import DomainListModule from "../../../modules/domain/list";

const DomainListPage = () => {
  const { t } = useTranslation();
  const { dispatch } = useBreadcrumb();

  useEffect(() => {
    dispatch({
      type: BreadcrumbActionType.Update,
      payload: [
        {
          link: "/",
          title: t("common.text.home"),
        },
        {
          link: "/domain",
          title: t("common.text.domain"),
          isDisabled: true,
        },
      ],
    });
  }, [dispatch, t]);

  return (
    <>
      <VuiHelmet title={t("pages.domain.list.metaTitle")} />

      <DomainListModule />
    </>
  );
};

export default DomainListPage;
