import { Badge, Card, Col, Row, Spin, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import VuiFormItemView from "../../../components/form-item-view";
import VuiPageTitle from "../../../components/page-title";
import { useBreadcrumb } from "../../../context/breadcrumb";
import { BreadcrumbActionType } from "../../../context/breadcrumb/reducer";
import { useGetInbox } from "../hooks/use-get-inbox";
import { useFormInbox } from "../hooks/use-form-inbox";
import VuiNumberFormat from "../../../components/number-format";

const { Text, Title } = Typography;

const InboxDetailModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { loadInboxData, isOnFetchingInboxData, inboxData } = useGetInbox();
  const { onRead, readLoading } = useFormInbox();
  const title = `${t("common.text.inbox")} | `;
  const { dispatch } = useBreadcrumb();

  useEffect(() => {
    dispatch({
      type: BreadcrumbActionType.Update,
      payload: [
        {
          link: "/",
          title: t("common.text.home"),
        },
        {
          link: "/inbox",
          title: t("common.text.inbox"),
        },
        {
          link: `/inbox/${id}`,
          title: inboxData?.fullname,
          isDisabled: true,
        },
      ],
    });
  }, [dispatch, t, inboxData?.fullname, id]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadInboxData(id, {
          with: [],
        });
        await onRead(id);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <VuiPageTitle
            title={
              <Title level={4} style={{ fontWeight: 500, marginBottom: 0 }}>
                {title}
                <Text style={{ fontWeight: 700 }}>
                  {inboxData?.fullname || ""}
                </Text>
              </Title>
            }
            onBackLink="/inbox"
          ></VuiPageTitle>
        </Col>
      </Row>

      <Spin size="large" spinning={isOnFetchingInboxData}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            <Card
              title={t("common.text.information")}
              style={{ height: "100%" }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} md={8}>
                  <VuiFormItemView
                    label={t("common.form.date.label")}
                    value={moment(inboxData?.created_at).format("D MMM YYYY")}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <VuiFormItemView
                    label={t("common.form.category.label")}
                    value={inboxData?.category}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <VuiFormItemView
                    label={t("common.form.name.label")}
                    value={inboxData?.fullname}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <VuiFormItemView
                    label={t("common.form.email.label")}
                    value={inboxData?.email}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <VuiFormItemView
                    label={t("common.form.number.label")}
                    value={inboxData?.number}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <VuiFormItemView
                    label={t("common.form.company.label")}
                    value={inboxData?.company}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <VuiFormItemView
                    label={t("common.form.website.label")}
                    value={inboxData?.website}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <VuiFormItemView
                    label={t("common.form.budget.label")}
                    value={<VuiNumberFormat value={inboxData?.budget} />}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <VuiFormItemView
                    label={t("common.form.projectDescription.label")}
                    value={inboxData?.project_description}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default InboxDetailModule;
