import { Card, Checkbox, Col, Form, Row, Space, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import { useCallback, useEffect, useMemo, useState } from "react";
import VuiPageTitle from "../../../components/page-title";
import VuiAccessible from "../../../components/accessible";
import VuiModalConfirmation from "../../../components/modal-confirmation";
import {
  formatOtherListModuleName,
  formatPermissionModuleName,
  formatPermissionScopeName,
  openNotification,
} from "../../../utils/helpers";
import { useGetRole } from "../hooks/use-get-role";
import { useFormRole } from "../hooks/use-form-role";
import { RoleFormData, RolePayloadType } from "./interface";
import PermissionRepository from "../../../repositories/permission-repository";
import { AxiosResponse } from "axios";
import { ICollection } from "../../../utils/interfaces/collection.interface";
import { Permission } from "../../../models/permission";
import _ from "lodash";
import BtnSelectAll from "../components/btn-select-all";
import { useAuth } from "../../../context/auth";

export type GroupPermission = {
  module: string;
  hasScope: boolean;
  permissions: Permission[];
  selfPermissions: Permission[];
  groupPermissions: Permission[];
  allPermissions: Permission[];
};

export type SelectedPermission = {
  id: number;
  uq_identifier: string;
};

export type OtherListPermission = {
  module: string;
  permissions: Permission[];
};

const emailConfigKeys = [
  "domain_expired_reminder",
  "invoice_expired_reminder",
  "invoice_approved",
  "invoice_completed",
  "invoice_paid",
  "invoice_rejected",
  "invoice_request",
  "invoice_send",
  "maintenance_expired_reminder",
  "lead_created",
  "lead_status_updated",
];

const otherListModules = [
  "category",
  "unit",
  "product",
  "industry",
  "credential",
  "project-type",
  "third-party",
  "gw-product",
];

const { Text } = Typography;

const RoleFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { fetchUser } = useAuth();
  const navigate = useNavigate();
  const title = id ? t("pages.role.detail.title") : t("pages.role.add.title");
  const { loadRoleData, isOnFetchingRoleData, roleData } = useGetRole();
  const { submitLoading, onSubmit, deleteLoading, onDelete } = useFormRole();
  const [groupPermissions, setGroupPermissions] = useState<GroupPermission[]>(
    []
  );
  const [otherListPermissions, setOtherListPermissions] = useState<
    OtherListPermission[]
  >([]);
  const [selectedOtherListPermissions, setSelectedOtherListPermissions] =
    useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedPermissions, setSelectedPermissions] = useState<
    SelectedPermission[]
  >([]);
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<number[]>(
    []
  );
  const [selectedEmailConfigs, setSelectedEmailConfigs] = useState<
    Record<string, string>
  >({});

  const [form] = Form.useForm();

  const onFinish = useCallback(
    async (data: RoleFormData) => {
      const permissionIds: number[] = selectedPermissionIds;
      if (selectedOtherListPermissions.length) {
        const selectedOtherList: OtherListPermission[] =
          otherListPermissions.filter((ol) =>
            selectedOtherListPermissions.includes(ol.module)
          );
        selectedOtherList.forEach((ol) => {
          ol.permissions.forEach((p) => permissionIds.push(p.id));
        });
      }

      const normalizeData: RolePayloadType = {
        name: data.name,
        permission_ids: selectedPermissionIds,
        email_configs: selectedEmailConfigs,
      };

      await onSubmit(normalizeData, id);

      await fetchUser();
    },
    [
      id,
      onSubmit,
      selectedPermissionIds,
      selectedEmailConfigs,
      selectedOtherListPermissions,
      otherListPermissions,
    ]
  );

  const handleCloseModalDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (id) {
      await onDelete(id).then(() => {
        setShowDeleteModal(false);
        openNotification(
          "success",
          t("notification.success.deleteItem", {
            item: t("common.text.role"),
          })
        );
        navigate(`/user-role?tab=role`);
      });
    }
  }, [id, navigate, onDelete, t]);

  const loadPermissions = useCallback(async () => {
    await PermissionRepository.all()
      .then((response: AxiosResponse<ICollection<Permission[]>>) => {
        const { data: responseData } = response.data;
        const scopedModules = [
          "invoice",
          "maintenance",
          "project",
          "domain",
          "lead",
        ];

        const groupPermissionData = Object.values(
          responseData.reduce((acc, permission) => {
            const module = permission.module;
            if (otherListModules.includes(module)) {
              return acc;
            }

            if (!acc[module]) {
              acc[module] = {
                module,
                hasScope: false,
                permissions: [],
                selfPermissions: [],
                groupPermissions: [],
                allPermissions: [],
              };
            }
            acc[module].permissions.push(permission);
            if (scopedModules.includes(module)) {
              acc[module].hasScope = true;
            }
            if (permission.scope === "all") {
              acc[module].allPermissions.push(permission);
            } else if (permission.scope === "self") {
              acc[module].selfPermissions.push(permission);
            } else if (permission.scope === "group") {
              acc[module].groupPermissions.push(permission);
            }
            return acc;
          }, {} as Record<string, { module: string; hasScope: boolean; permissions: Permission[]; selfPermissions: Permission[]; groupPermissions: Permission[]; allPermissions: Permission[] }>)
        );
        setGroupPermissions(groupPermissionData);

        const otherListData = Object.values(
          responseData.reduce((acc, permission) => {
            const module = permission.module;
            if (!otherListModules.includes(module)) {
              return acc;
            }

            if (!acc[module]) {
              acc[module] = {
                module,
                permissions: [],
              };
            }
            acc[module].permissions.push(permission);
            return acc;
          }, {} as Record<string, { module: string; permissions: Permission[] }>)
        );
        setOtherListPermissions(otherListData);
      })
      .catch(() => {});
  }, []);

  const handleCheckPermission = useCallback(
    (checked: boolean, permission: Permission) => {
      let clone = [...selectedPermissions];
      if (checked) {
        if (permission.uq_identifier) {
          clone = selectedPermissions.filter(
            (p) => p.uq_identifier !== permission.uq_identifier
          );
        }
        clone.push({
          id: permission.id,
          uq_identifier: permission.uq_identifier,
        });
      } else {
        const permissionIdx = clone.findIndex((p) => p.id === permission.id);
        if (permissionIdx !== -1) {
          clone.splice(permissionIdx, 1);
        }
      }

      setSelectedPermissions(clone);
      setSelectedPermissionIds(clone.map((p) => p.id));
    },
    [selectedPermissions]
  );

  const handleSelectAll = useCallback(
    (scope: string, module: GroupPermission, checked: boolean) => {
      let clone = [...selectedPermissions];
      const groupPermissionIds = module.permissions.map((p) => p.id);
      clone = clone.filter((p) => !groupPermissionIds.includes(p.id));

      if (checked) {
        if (scope === "all") clone = clone.concat(module.allPermissions);
        if (scope === "self") clone = clone.concat(module.selfPermissions);
        if (scope === "group") clone = clone.concat(module.groupPermissions);
      }

      setSelectedPermissions(clone);
      setSelectedPermissionIds(clone.map((p) => p.id));
    },
    [selectedPermissions]
  );

  useEffect(() => {
    if (id) {
      (async () => {
        await loadRoleData(id, {
          with: ["gateSetting.permissions"],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && roleData) {
      form.setFieldsValue({
        ...roleData,
      });
      if (roleData?.email_configs) {
        setSelectedEmailConfigs(roleData?.email_configs);
      }
      if (roleData.gate_setting?.permissions) {
        const selectedData: SelectedPermission[] = [];
        const selectedOtherListData: string[] = [];
        roleData.gate_setting?.permissions.forEach((p) => {
          if (otherListModules.includes(p.module)) {
            if (!selectedOtherListData.includes(p.module)) {
              selectedOtherListData.push(p.module);
            }
          } else {
            selectedData.push({
              id: p.id,
              uq_identifier: p.uq_identifier,
            });
          }
        });
        setSelectedPermissions(selectedData);
        setSelectedPermissionIds(selectedData.map((p) => p.id));
        setSelectedOtherListPermissions(selectedOtherListData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleData]);

  const getEmailConfigKeyName = (key: string): string => {
    return _.startCase(key.split("_").join(" "));
  };

  const handleOnChangeEmailConfig = useCallback(
    (checked: boolean, key: string, value: string) => {
      setSelectedEmailConfigs((prevState) => ({
        ...prevState,
        [key]: checked ? value : "none",
      }));
    },
    []
  );

  const handleOnChangeOtherList = useCallback(
    (checked: boolean, value: string) => {
      setSelectedOtherListPermissions((prevState) => {
        let clone = [...prevState];
        if (checked) {
          clone.push(value);
        } else {
          clone = clone.filter((item) => item !== value);
        }
        return clone;
      });
    },
    [selectedOtherListPermissions]
  );

  const handleSelectAllOtherList = useCallback(() => {
    if (selectedOtherListPermissions.length === otherListPermissions.length) {
      setSelectedOtherListPermissions([]);
    } else {
      setSelectedOtherListPermissions(
        otherListPermissions.map((otherList) => otherList.module)
      );
    }
  }, [selectedOtherListPermissions, otherListPermissions]);

  useMemo(() => {
    (async () => {
      await loadPermissions();
    })();
  }, []);

  return (
    <>
      <VuiPageTitle title={title} onBackLink="/user-role?tab=role" />

      <Spin size="large" spinning={isOnFetchingRoleData}>
        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col md={24} xs={24}>
              <Card title={t("common.text.information")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Form.Item
                      name="name"
                      label={t("common.form.name.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.name.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.name.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={24} xs={24}>
              <Card title={t("common.text.emailConfigs")}>
                <Space direction="vertical" size={16}>
                  <Row gutter={[16, 16]}>
                    {emailConfigKeys.map((key) => (
                      <>
                        <Col xs={24} sm={12} md={8}>
                          <Checkbox
                            checked={selectedEmailConfigs[key] === "all"}
                            onChange={(e) => {
                              handleOnChangeEmailConfig(
                                e.target.checked,
                                key,
                                "all"
                              );
                            }}
                          >
                            {getEmailConfigKeyName(key)} All
                          </Checkbox>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                          <Checkbox
                            checked={selectedEmailConfigs[key] === "self"}
                            onChange={(e) => {
                              handleOnChangeEmailConfig(
                                e.target.checked,
                                key,
                                "self"
                              );
                            }}
                          >
                            {getEmailConfigKeyName(key)} Personal-Data
                          </Checkbox>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                          <Checkbox
                            checked={selectedEmailConfigs[key] === "group"}
                            onChange={(e) => {
                              handleOnChangeEmailConfig(
                                e.target.checked,
                                key,
                                "group"
                              );
                            }}
                          >
                            {getEmailConfigKeyName(key)} Group-Data
                          </Checkbox>
                        </Col>
                      </>
                    ))}
                  </Row>
                </Space>
              </Card>
            </Col>

            <Col md={24} xs={24}>
              <Card title={t("common.text.accessRights")}>
                <Space direction="vertical" size={20}>
                  {groupPermissions.map((groupPermission, idx) => (
                    <Card
                      title={formatPermissionModuleName(groupPermission.module)}
                      key={`group_permission_${idx}`}
                    >
                      <Space direction="vertical" size={16}>
                        <Row gutter={[16, 16]}>
                          <Col
                            xs={24}
                            sm={groupPermission.hasScope ? 12 : 24}
                            md={groupPermission.hasScope ? 8 : 24}
                          >
                            <BtnSelectAll
                              onClick={(isSelectAll) => {
                                handleSelectAll(
                                  "all",
                                  groupPermission,
                                  isSelectAll
                                );
                              }}
                              selectedPermissions={selectedPermissions}
                              scopePermissions={groupPermission.allPermissions}
                            />
                          </Col>

                          {groupPermission.hasScope && (
                            <>
                              <Col xs={24} sm={12} md={8}>
                                <BtnSelectAll
                                  onClick={(isSelectAll) => {
                                    handleSelectAll(
                                      "self",
                                      groupPermission,
                                      isSelectAll
                                    );
                                  }}
                                  selectedPermissions={selectedPermissions}
                                  scopePermissions={
                                    groupPermission.selfPermissions
                                  }
                                />
                              </Col>
                              <Col xs={24} sm={12} md={8}>
                                <BtnSelectAll
                                  onClick={(isSelectAll) => {
                                    handleSelectAll(
                                      "group",
                                      groupPermission,
                                      isSelectAll
                                    );
                                  }}
                                  selectedPermissions={selectedPermissions}
                                  scopePermissions={
                                    groupPermission.groupPermissions
                                  }
                                />
                              </Col>
                            </>
                          )}
                        </Row>

                        <Row gutter={[16, 16]}>
                          {groupPermission.permissions.map(
                            (permission, permissionIdx) => (
                              <Col
                                xs={24}
                                sm={groupPermission.hasScope ? 12 : 24}
                                md={groupPermission.hasScope ? 8 : 24}
                                key={`group_permission_${idx}_permission_${permissionIdx}`}
                              >
                                <Checkbox
                                  checked={selectedPermissionIds.includes(
                                    permission.id
                                  )}
                                  onChange={(e) => {
                                    handleCheckPermission(
                                      e.target.checked,
                                      permission
                                    );
                                  }}
                                >
                                  {formatPermissionScopeName(
                                    permission.scope_name,
                                    groupPermission.hasScope
                                  )}
                                </Checkbox>
                              </Col>
                            )
                          )}
                        </Row>
                      </Space>
                    </Card>
                  ))}

                  {otherListPermissions.length > 0 && (
                    <Card title="Other List">
                      <Space direction="vertical" size={16}>
                        <Row gutter={[16, 16]}>
                          <Col xs={24} sm={24} md={24}>
                            <Text
                              className="role-select-all"
                              strong
                              onClick={handleSelectAllOtherList}
                            >
                              {selectedOtherListPermissions.length ===
                              otherListPermissions.length
                                ? "Deselect"
                                : "Select"}{" "}
                              all
                            </Text>
                          </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                          {otherListPermissions.map((otherList, idx) => (
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              key={`other_list_module_${idx}`}
                            >
                              <Checkbox
                                checked={selectedOtherListPermissions.includes(
                                  otherList.module
                                )}
                                onChange={(e) => {
                                  handleOnChangeOtherList(
                                    e.target.checked,
                                    otherList.module
                                  );
                                }}
                              >
                                {formatOtherListModuleName(otherList.module)}
                              </Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </Space>
                    </Card>
                  )}
                </Space>
              </Card>
            </Col>

            <Col md={24} xs={24}>
              <VuiFormActionButton
                cancelBtnLink="/user-role?tab=role"
                isLoading={submitLoading}
              />
            </Col>

            {id && (
              <VuiAccessible access="role.destroy">
                <Col xs={24}>
                  <Text
                    className="cursor-pointer"
                    onClick={() => setShowDeleteModal(true)}
                    title={t("common.text.delete")}
                    type="secondary"
                  >
                    {t("common.text.delete")}
                  </Text>
                </Col>
              </VuiAccessible>
            )}
          </Row>
        </Form>
      </Spin>

      <VuiModalConfirmation
        show={showDeleteModal}
        isLoading={deleteLoading}
        onSubmit={handleConfirmDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
};

export default RoleFormModule;
