import { TFunction } from "react-i18next";
import { MenuDataType } from "./menu";

export const generateOtherMenu = (t: TFunction): MenuDataType[] => {
  return [
    {
      title: t("common.text.category"),
      key: "/other-list/category",
      access: "category.index",
    },
    {
      title: t("common.text.industry"),
      key: "/other-list/industry",
      access: "industry.index",
    },
    {
      title: t("common.text.credential"),
      key: "/other-list/credential",
      access: "credential.index",
    },
    {
      title: t("common.text.projectType"),
      key: "/other-list/project-type",
      access: "project-type.index",
    },
    {
      title: t("common.text.thirdParty"),
      key: "/other-list/third-party",
      access: "third-party.index",
    },
    {
      title: t("common.text.unit"),
      key: "/other-list/unit",
      access: "unit.index",
    },
    {
      title: t("common.text.product"),
      key: "/other-list/product",
      access: "product.index",
    },
    {
      title: t("common.text.gwProduct"),
      key: "/other-list/gw-product",
      access: "gw-product.index",
    },
  ];
};
