import { Empty, Select } from "antd";
import { VuiSelectProps } from "./interface";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import VuiLoadingScreen from "../loading-screen";
import useDebounce from "../../hooks/use-debounce";

const VuiSelect = (props: VuiSelectProps) => {
  const { t } = useTranslation();
  const {
    value,
    repository = null,
    repositoryName = "select",
    repositoryParams = {},
    mode,
    className = "",
    disabled = false,
    placeholder = t("common.text.select"),
    labelKey = "name",
    valueKey = "id",
    defaultOptions = [],
    onChange,
    isCombineOption = false,
  } = props;

  const [options, setOptions] = useState<any[]>(defaultOptions);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 400);
  const [hasInit, setHasInit] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    if (repository && repositoryName) {
      setIsLoading(true);

      repository[repositoryName]({
        ...repositoryParams,
        ...(debouncedSearch ? { search: debouncedSearch } : {}),
      })
        .then((response: AxiosResponse) => {
          if (isCombineOption) {
            setOptions([...defaultOptions, ...response.data.data]);
          } else {
            setOptions(response.data.data);
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }

    setHasInit(true);
  }, [
    repository,
    repositoryParams,
    repositoryName,
    debouncedSearch,
    defaultOptions,
    isCombineOption,
  ]);

  useEffect(() => {
    if (hasInit) {
      (async () => {
        await loadData();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <Select
      showSearch
      allowClear
      mode={mode}
      size="large"
      value={value}
      disabled={disabled}
      options={options}
      placeholder={placeholder}
      labelInValue
      className={className}
      dropdownMatchSelectWidth={false}
      fieldNames={{
        value: valueKey,
        label: labelKey,
      }}
      onChange={(value) => {
        if (typeof onChange === "function") {
          onChange(value);
        }
      }}
      onSearch={(value) => {
        setSearch(value);
      }}
      filterOption={false}
      notFoundContent={
        isLoading ? <VuiLoadingScreen height="150px" /> : <Empty />
      }
      onDropdownVisibleChange={(open) => {
        if (open) {
          (async () => {
            await loadData();
          })();
        } else {
          setSearch("");
          if (repository) {
            setOptions([]);
          }
        }
      }}
      {...props}
    />
  );
};

export default VuiSelect;
