import axios, { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import { IResource } from "../interfaces/resource.interface";
import { User } from "../../models/user";
import { ENV } from "../../constants";

export interface OAuth2TokenContract {
  access_token: string;
  refresh_token: string;
  expires_in: number | string;
  token_type: string;
}

const KEY_ACCESS_TOKEN = "auth.access_token";
const KEY_REFRESH_TOKEN = "auth.refresh_token";
const KEY_EXPIRES_IN = "auth.expires_in";
const KEY_EXPIRES_AT = "auth.expires_at";

class PassportService {
  constructor() {
    const accessToken = this.getAccessToken();

    if (accessToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    }
  }

  getAccessToken = () => {
    const accessToken = window.localStorage.getItem(KEY_ACCESS_TOKEN);
    if (!accessToken) {
      return false;
    }

    const expiredAt = window.localStorage.getItem(KEY_EXPIRES_AT);

    if (expiredAt) {
      const exp = moment(expiredAt, "YYYY-MM-DD HH:mm:ss");

      if (exp <= moment()) {
        return false;
      } else {
        let diff = moment.duration(exp.diff(moment()));
        let ms = Math.ceil(diff.asMilliseconds());
        setTimeout(async () => {
          if (exp <= moment()) {
            this.setToken(null);
            window.location.reload();
          }
        }, ms);
      }
    }

    return accessToken;
  };

  setToken = (oauthToken: OAuth2TokenContract | null) => {
    if (oauthToken) {
      const expiredAt = moment()
        .add(oauthToken.expires_in, "seconds")
        .format("YYYY-MM-DD HH:mm:ss");

      localStorage.setItem(KEY_ACCESS_TOKEN, oauthToken.access_token);
      localStorage.setItem(KEY_REFRESH_TOKEN, oauthToken.refresh_token);
      localStorage.setItem(KEY_EXPIRES_IN, String(oauthToken.expires_in));
      localStorage.setItem(KEY_EXPIRES_AT, String(expiredAt));

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${oauthToken.access_token}`;
    } else {
      localStorage.removeItem(KEY_ACCESS_TOKEN);
      localStorage.removeItem(KEY_REFRESH_TOKEN);
      localStorage.removeItem(KEY_EXPIRES_IN);
      localStorage.removeItem(KEY_EXPIRES_AT);

      delete axios.defaults.headers.common["Authorization"];
    }
  };

  login = (username: string, password: string, scope: string = "*") => {
    const credentials = {
      username,
      password,
      scope,
      grant_type: "password",
      client_id: ENV.getPassportClientId(),
      client_secret: ENV.getPassportClientSecret(),
    };

    return new Promise(async (resolve, reject) => {
      await axios
        .post(`${ENV.getApiEndPoint()}/oauth/token`, credentials)
        .then((response) => {
          this.setToken(response.data);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          this.setToken(null);
          reject(error);
        });
    });
  };

  logout = async () => {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(`${ENV.getApiEndPoint()}/api/account/logout`)
        .then((response: AxiosResponse) => resolve(response))
        .catch((error: AxiosError) => reject(error));

      this.setToken(null);
    });
  };

  fetchUser = (): Promise<AxiosResponse<IResource<User>>> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ENV.getApiEndPoint()}/api/account`)
        .then((response: AxiosResponse) => resolve(response))
        .catch((error: AxiosError) => {
          this.setToken(null);
          reject(error);
        });
    });
  };
}

const instance = new PassportService();

export default instance;
