import { useTranslation } from "react-i18next";
import VuiHelmet from "../../../components/helmet";
import InvoiceDetailModule from "../../../modules/invoice/detail";

const InvoiceDetailPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <VuiHelmet title={t("pages.invoice.detail.metaTitle")} />

      <InvoiceDetailModule />
    </>
  );
};

export default InvoiceDetailPage;
