import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { handleBackendError, openNotification } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CredentialRepository from "../../../repositories/credential-repository";
import { IResource } from "../../../utils/interfaces/resource.interface";
import { Credential } from "../../../models/credential";
import { CredentialPayloadType } from "../form/interface";

export const useFormCredential = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const onDelete = useCallback(
    async (id: number | string) => {
      setDeleteLoading(true);

      return new Promise((resolve, reject) => {
        CredentialRepository.delete(id)
          .then((response: AxiosResponse) => {
            resolve(response);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
            reject(e);
          })
          .finally(() => {
            setDeleteLoading(false);
          });
      });
    },
    [t]
  );

  const onSubmit = useCallback(
    async (data: CredentialPayloadType, id: string | null = null) => {
      setSubmitLoading(true);

      await (id
        ? CredentialRepository.update(id, data)
        : CredentialRepository.create(data)
      )
        .then((response: AxiosResponse<IResource<Credential>>) => {
          navigate("/other-list/credential");
          openNotification(
            "success",
            t(`notification.success.${id ? "updateItem" : "createItem"}`, {
              item: t("common.text.credential"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, navigate]
  );

  return {
    onSubmit,
    submitLoading,
    deleteLoading,
    onDelete,
  };
};
