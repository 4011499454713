import _ from "lodash";
import { FileList } from "./interface";
import { Media } from "../../models/media";

export const toFileList = (
  data: FileList[] | FileList | Media | Media[]
): FileList[] => {
  if (!data) return [];

  if (Array.isArray(data)) {
    return _.map(data as FileList[], (collection: Media | FileList) => {
      return {
        id: collection.id,
        name: collection.name,
        status: "done",
        url: collection.url,
      } as FileList;
    });
  } else {
    return [
      {
        id: data?.id,
        name: data?.name,
        status: "done",
        url: data?.url,
      },
    ];
  }
};

export const urlToFileList = (data: string | null | string[]): FileList[] => {
  if (!data) return [];

  if (Array.isArray(data)) {
    return _.map(data, (collection) => {
      return {
        id: 1,
        name: "default",
        status: "done",
        url: collection,
      } as FileList;
    });
  } else {
    return [
      {
        id: 1,
        name: "default",
        status: "done",
        url: data,
      },
    ];
  }
};
