import React, { useCallback, useEffect, useState } from "react";
import Menu from "antd/es/menu";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuDataType } from "../../constants/menu";
import { VuiSidebarProps } from "./interface";
import { canAccess } from "../../utils/helpers";
import { useAuth } from "../../context/auth";

const { SubMenu } = Menu;

const VuiSidebar = ({
  menus,
  mode = "horizontal",
  onChange,
  className = "main-menu-component",
}: VuiSidebarProps) => {
  const { state } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [rootSubmenuKeys, setRootSubmenuKeys] = useState([""]);
  const [currentMenu, setCurrentMenu] = useState("");
  const [openKeys, setOpenKeys] = React.useState([""]);

  useEffect(() => {
    const tempSubMenuKeys = _.map(menus, "key");
    setRootSubmenuKeys(tempSubMenuKeys);
    setCurrentMenu(location?.pathname);
  }, [menus, location]);

  const menuHandleClick = (e: any) => {
    setCurrentMenu(e.key);

    navigate(e.key);

    if (onChange) {
      onChange();
    }
  };

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const renderMenu = useCallback((menus: MenuDataType[]) => {
    return menus.map((item) => {
      if (canAccess(item.access, state.user?.permissions as string[])) {
        if (item.children) {
          if (item.type === "group") {
            return (
              <Menu.ItemGroup key={`${item.key}`} title={item.title}>
                {renderMenu(item.children)}
              </Menu.ItemGroup>
            );
          }

          return (
            <SubMenu
              key={`${item.key}`}
              title={item.title}
              icon={item.icon}
              popupClassName="dropdown-main-menu"
            >
              {renderMenu(item.children)}
            </SubMenu>
          );
        } else {
          return (
            <Menu.Item key={`${item.key}`} icon={item.icon}>
              {item.title}
            </Menu.Item>
          );
        }
      }
    });
  }, []);

  return (
    <Menu
      mode={mode}
      onClick={menuHandleClick}
      selectedKeys={[currentMenu]}
      onOpenChange={onOpenChange}
      openKeys={openKeys}
      itemType="group"
      className={className}
    >
      {renderMenu(menus)}
    </Menu>
  );
};

export default VuiSidebar;
