import { Card, Col, Form, Radio, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProject } from "../hooks/use-get-project";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import VuiSelect from "../../../components/select";
import { useCallback, useEffect, useState } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormProject } from "../hooks/use-form-project";
import { ProjectFormData, ProjectPayloadType } from "./interface";
import { convertToSelectValue } from "../../../components/select/function";
import UserRepository from "../../../repositories/user-repository";
import CustomerRepository from "../../../repositories/customer-repository";
import VuiAccessible from "../../../components/accessible";
import VuiModalConfirmation from "../../../components/modal-confirmation";
import { openNotification } from "../../../utils/helpers";
import ConstantRepository from "../../../repositories/constant-repository";
import ProjectTypeRepository from "../../../repositories/project-type-repository";
import { ValueType } from "../../../components/select/interface";
import { ProjectCategoryEnum } from "../../../models/project";

const { Text } = Typography;

const ProjectFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = id
    ? t("pages.project.detail.title")
    : t("pages.project.add.title");
  const { loadDetailData, isOnFetchingDetailData, detailData } =
    useGetProject();
  const { submitLoading, onSubmit, deleteLoading, onDelete } = useFormProject();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [form] = Form.useForm();

  const watchCategory = Form.useWatch("category", form);

  const onFinish = useCallback(
    async (data: ProjectFormData) => {
      const normalizeData: ProjectPayloadType = {
        customer_id: data.customer_id.value as number,
        type_id: data?.type_id?.value as number,
        code: data.code,
        description: data.description,
        assignee_ids: data.assignee_ids.map((item) => item.value as number),
        category: data?.category,
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  const handleCloseModalDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (id) {
      await onDelete(id).then(() => {
        setShowDeleteModal(false);
        openNotification(
          "success",
          t("notification.success.deleteItem", {
            item: t("common.text.project"),
          })
        );
        navigate(`/project`);
      });
    }
  }, [id, navigate, onDelete, t]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadDetailData(id, {
          with: ["customer.type", "projectManager", "type", "assignees.user"],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && detailData) {
      form.setFieldsValue({
        ...detailData,
        customer_id: convertToSelectValue({
          ...detailData.customer,
          name: `${detailData.customer.alias_name} - ${
            detailData.customer.type.label != "None"
              ? `${detailData.customer.type.label} `
              : ""
          }${detailData.customer.name}`,
        }) as ValueType,
        project_manager_id: convertToSelectValue(detailData.project_manager),
        type_id: convertToSelectValue(detailData.type),
        assignee_ids: detailData.assignees.map(
          (item) => convertToSelectValue(item.user) as ValueType
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  return (
    <>
      <VuiPageTitle title={title} onBackLink="/project" />

      <Spin size="large" spinning={isOnFetchingDetailData}>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          initialValues={{
            category: ProjectCategoryEnum.INTERNAL,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col md={16} xs={24}>
              <Card title={t("common.text.information")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      name="category"
                      label={t("common.form.category.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.category.label"),
                          }),
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={ProjectCategoryEnum.INTERNAL}>
                          {t("common.text.project")}
                        </Radio>
                        <Radio value={ProjectCategoryEnum.GOOGLE_WORKSPACE}>
                          {t("common.text.googleWorkspace")}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="customer_id"
                      label={t("common.form.client.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.client.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={CustomerRepository}
                        placeholder={t("common.form.client.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="code"
                      label="ID"
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: "ID",
                          }),
                        },
                      ]}
                    >
                      <Input size="large" placeholder="ID" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="assignee_ids"
                      label={t("common.form.assigneeUser.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.assigneeUser.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={UserRepository}
                        placeholder={t("common.form.assigneeUser.placeholder")}
                        mode="multiple"
                      />
                    </Form.Item>
                  </Col>

                  {watchCategory === ProjectCategoryEnum.INTERNAL && (
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="type_id"
                        label={t("common.form.type.label")}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: t("common.form.type.label"),
                            }),
                          },
                        ]}
                      >
                        <VuiSelect
                          repository={ProjectTypeRepository}
                          placeholder={t("common.form.type.placeholder")}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col xs={24}>
                    <Form.Item
                      name="description"
                      label={t("common.form.description.label")}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.description.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={16} xs={24}>
              <VuiFormActionButton
                cancelBtnLink="/project"
                isLoading={submitLoading}
              />
            </Col>

            {id && (
              <VuiAccessible access="project.destroy">
                <Col xs={24}>
                  <Text
                    className="cursor-pointer"
                    onClick={() => setShowDeleteModal(true)}
                    title={t("common.text.delete")}
                    type="secondary"
                  >
                    {t("common.text.delete")}
                  </Text>
                </Col>
              </VuiAccessible>
            )}
          </Row>
        </Form>
      </Spin>

      <VuiModalConfirmation
        show={showDeleteModal}
        isLoading={deleteLoading}
        onSubmit={handleConfirmDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
};

export default ProjectFormModule;
