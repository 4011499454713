import { Card, Spin, Typography } from "antd";
import { ReactNode } from "react";

const { Title, Text } = Typography;

interface WidgetProps {
  isLoading: boolean;
  title: string;
  subtitle: string;
  data: ReactNode;
}

const Widget = ({ isLoading, data, subtitle, title }: WidgetProps) => {
  return (
    <Card>
      <Spin spinning={isLoading}>
        <Title level={5} style={{ marginBottom: 0 }}>
          {title}
        </Title>
        <Text style={{ fontSize: 12 }}>{subtitle}</Text>

        <Title
          level={4}
          style={{ marginTop: 12, marginBottom: 0, color: "#0078F0" }}
        >
          {data}
        </Title>
      </Spin>
    </Card>
  );
};

export default Widget;
