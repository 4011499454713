import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCustomer } from "../hooks/use-get-customer";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import { useCallback, useEffect, useState } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormCustomer } from "../hooks/use-form-customer";
import { CustomerFormData, CustomerPayloadType } from "./interface";
import VuiSelect from "../../../components/select";
import { CopyOutlined } from "@ant-design/icons";
import { openNotification } from "../../../utils/helpers";
import ConstantRepository from "../../../repositories/constant-repository";
import VuiAccessible from "../../../components/accessible";
import VuiModalConfirmation from "../../../components/modal-confirmation";
import { convertToSelectValue } from "../../../components/select/function";
import { ValueType } from "../../../components/select/interface";
import IndustryRepository from "../../../repositories/industry-repository";

const { TextArea } = Input;
const { Text } = Typography;

const CustomerFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = id
    ? t("pages.client.detail.title")
    : t("pages.client.add.title");
  const { loadCustomerData, isOnFetchingCustomerData, customerData } =
    useGetCustomer();
  const { submitLoading, onSubmit, onDelete, deleteLoading, errors } =
    useFormCustomer();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [form] = Form.useForm<CustomerFormData>();

  const watchIsSendInvoiceEmail = Form.useWatch("is_send_invoice_email", form);
  const watchIsSendInvoiceWhatsapp = Form.useWatch(
    "is_send_invoice_whatsapp",
    form
  );

  const onFinish = useCallback(
    async (data: CustomerFormData) => {
      const normalizeData: CustomerPayloadType = {
        ...data,
        type_id: data.type_id.value as number,
        industry_id: data.industry_id?.value as number,
      };

      delete normalizeData.code;
      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  const handleCopyClientId = () => {
    const clientId = form.getFieldValue("code");
    navigator.clipboard.writeText(clientId);

    openNotification(
      "success",
      t("notification.success.copyItem", {
        item: t("common.form.clientId.label"),
      })
    );
  };

  const handleCloseModalDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (id) {
      await onDelete(id).then(() => {
        setShowDeleteModal(false);
        openNotification(
          "success",
          t("notification.success.deleteItem", {
            item: t("common.text.client"),
          })
        );
        navigate(`/client`);
      });
    }
  }, [id, navigate, onDelete, t]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadCustomerData(id, {
          with: ["type", "industry", "contactWhatsapp", "contactEmails"],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && customerData) {
      form.setFieldsValue({
        ...customerData,
        type_id: {
          label: customerData?.type?.label,
          value: customerData?.type?.id,
        },
        industry_id: convertToSelectValue(customerData?.industry) as ValueType,
        contact_emails: customerData?.contact_emails.map(
          (contact) => contact.email
        ),
        contact_whatsapp: customerData?.contact_whatsapp.map(
          (contact) => contact.phone
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData]);

  return (
    <>
      <VuiPageTitle title={title} onBackLink="/client" />

      <Spin size="large" spinning={isOnFetchingCustomerData}>
        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col md={18} xs={24}>
              <Card title={t("common.text.information")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="type_id"
                      label={t("common.form.clientType.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.clientType.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={ConstantRepository}
                        repositoryParams={{
                          for: "customer_type",
                        }}
                        labelKey="label"
                        placeholder={t("common.form.clientType.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={9}>
                    <Form.Item
                      name="name"
                      label={t("common.form.clientName.label")}
                      tooltip={t("common.text.accordingToLegality")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.clientName.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.clientName.placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={9}>
                    <Input.Group
                      style={{ display: "flex", alignItems: "end" }}
                      compact
                    >
                      <Form.Item
                        name="code"
                        label={t("common.form.clientId.label")}
                        tooltip={t("common.text.autoGenerated")}
                        style={{ width: id ? "calc(100% - 40px)" : "100%" }}
                        className="mb0"
                      >
                        <Input
                          size="large"
                          placeholder={t("common.form.clientId.placeholder")}
                          disabled
                        />
                      </Form.Item>
                      <Tooltip title="Copy">
                        <Button
                          onClick={handleCopyClientId}
                          size="large"
                          disabled={!id}
                          icon={<CopyOutlined />}
                        />
                      </Tooltip>
                    </Input.Group>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item
                      name="industry_id"
                      label={t("common.form.industry.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.industry.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={IndustryRepository}
                        placeholder={t("common.form.industry.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item
                      name="alias_name"
                      label={t("common.form.aliasName.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.aliasName.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.aliasName.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item
                      name="phone_number"
                      label={t("common.form.phoneNumber.label")}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9-()+\\s]*$"),
                          message: t("validation.invalid", {
                            item: t("common.form.phoneNumber.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.phoneNumber.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24}>
                    <Form.Item
                      name="billing_address"
                      label={t("common.form.billingAddress.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.billingAddress.label"),
                          }),
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        size="large"
                        placeholder={t(
                          "common.form.billingAddress.placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24}>
                    <Form.Item
                      name="finance_name"
                      label={t("common.form.financeName.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.financeName.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.financeName.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item label="Notification Preference" required>
                      <Space>
                        <Form.Item
                          name="is_send_invoice_email"
                          valuePropName="checked"
                        >
                          <Checkbox>Email</Checkbox>
                        </Form.Item>
                        <Form.Item
                          name="is_send_invoice_whatsapp"
                          valuePropName="checked"
                        >
                          <Checkbox>WhatsApp</Checkbox>
                        </Form.Item>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="contact_emails"
                      label={t("common.form.financeEmail.label")}
                      rules={[
                        {
                          required: !!form.getFieldValue(
                            "is_send_invoice_email"
                          ),
                          message: t("validation.required", {
                            item: t("common.form.financeEmail.label"),
                          }),
                        },
                      ]}
                    >
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder={t("common.form.financeEmail.placeholder")}
                        options={[]}
                        open={false}
                        size="large"
                      />
                    </Form.Item>
                    {errors?.contact_emails &&
                    errors?.contact_emails?.length > 0 ? (
                      <div
                        className="ant-form-item-explain ant-form-item-explain-connected"
                        role="alert"
                        style={{ marginTop: -45 }}
                      >
                        <div className="ant-form-item-explain-error">
                          {t("validation.invalid", {
                            item: t("common.form.financeEmail.label"),
                          })}
                        </div>
                      </div>
                    ) : null}
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="contact_whatsapp"
                      label={t("common.form.financeWhatsapp.label")}
                      rules={[
                        {
                          required: !!form.getFieldValue(
                            "is_send_invoice_whatsapp"
                          ),
                          message: t("validation.required", {
                            item: t("common.form.financeWhatsapp.label"),
                          }),
                        },
                      ]}
                      tooltip={{
                        title: t("common.text.phoneWithCountryCodeTooltip"),
                      }}
                    >
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder={t(
                          "common.form.financeWhatsapp.placeholder"
                        )}
                        options={[]}
                        open={false}
                        size="large"
                      />
                    </Form.Item>
                    {errors?.contact_whatsapp &&
                    errors?.contact_whatsapp?.length > 0 ? (
                      <div
                        className="ant-form-item-explain ant-form-item-explain-connected"
                        role="alert"
                        style={{ marginTop: -45 }}
                      >
                        <div className="ant-form-item-explain-error">
                          {t("validation.invalid", {
                            item: t("common.form.financeWhatsapp.label"),
                          })}
                        </div>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={18} xs={24}>
              <VuiFormActionButton
                cancelBtnLink="/client"
                isLoading={submitLoading}
              />
            </Col>

            {id && (
              <VuiAccessible access="customer.destroy">
                <Col xs={24}>
                  <Text
                    className="cursor-pointer"
                    onClick={() => setShowDeleteModal(true)}
                    title={t("common.text.delete")}
                    type="secondary"
                  >
                    {t("common.text.delete")}
                  </Text>
                </Col>
              </VuiAccessible>
            )}
          </Row>
        </Form>
      </Spin>

      <VuiModalConfirmation
        show={showDeleteModal}
        isLoading={deleteLoading}
        onSubmit={handleConfirmDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
};

export default CustomerFormModule;
