import { Col, Input, Row, Table, Tag, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import VuiPageTitle from "../../../components/page-title";
import { useTranslation } from "react-i18next";
import VuiButton from "../../../components/button";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table/interface";
import { LeadDataList } from "./interface";
import VuiTableActionButton from "../../../components/table-action-button";
import { useGetLead } from "../hooks/use-get-lead";
import VuiAccessible from "../../../components/accessible";
import { IPaginationParams } from "../../../utils/interfaces/pagination-params.interface";
import {
  leadStatusOptions,
  overrideTableSortOrder,
  snakeCaseToTitleCase,
  uiFilterData,
} from "../../../utils/helpers";
import VuiSelect from "../../../components/select";
import { ValueType } from "../../../components/select/interface";
import {
  DEFAULT_PAGE_SIZE,
  LEAD_STATUS_COLOR,
  LOCALSTORAGE_FILTER_DATA_KEY,
} from "../../../constants";
import _ from "lodash";
import useDebounce from "../../../hooks/use-debounce";
import IndustryRepository from "../../../repositories/industry-repository";
import UserRepository from "../../../repositories/user-repository";
import moment from "moment";
import ConstantRepository from "../../../repositories/constant-repository";

const qs = require("qs-lite");

const { Text } = Typography;

const LeadListModule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, loadData, isOnFetchingData, totalData } = useGetLead();
  const [hasInitialize, setHasInitialize] = useState<boolean>(false);

  const [selectedIndustry, setSelectedIndustry] = useState<
    ValueType | undefined
  >();

  const [selectedPriority, setSelectedPriority] = useState<
    ValueType | undefined
  >();

  const [selectedUser, setSelectedUser] = useState<ValueType | undefined>();

  const [selectedStatus, setSelectedStatus] = useState<ValueType | undefined>();

  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 250);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tableQuery, setTableQuery] = useState<IPaginationParams>({
    page: Number(searchParams.get("page")) || 1,
    per_page: Number(searchParams.get("perPage")) || DEFAULT_PAGE_SIZE,
    sorted_by: searchParams.get("sortedBy"),
    order_by: searchParams.get("orderBy") as string,
  });

  const handleChangeSearchParams = useCallback(async () => {
    const params = {} as IPaginationParams;

    Object.assign(params, {
      page: tableQuery.page,
    });

    Object.assign(params, {
      perPage: tableQuery.per_page,
    });

    if (debouncedSearch) {
      Object.assign(params, {
        search: debouncedSearch,
      });
    }

    if (tableQuery.order_by) {
      Object.assign(params, {
        orderBy: tableQuery.order_by,
      });
    }

    if (tableQuery.sorted_by) {
      Object.assign(params, {
        sortedBy: tableQuery.sorted_by,
      });
    }

    if (selectedIndustry) {
      Object.assign(params, {
        industry: selectedIndustry.value,
      });
    } else {
      const recentIndustry = uiFilterData.get(
        LOCALSTORAGE_FILTER_DATA_KEY.industry
      );
      if (!recentIndustry) {
        searchParams.delete("industry");
      } else {
        const paramsIndustryIds = _.map(
          searchParams.getAll("industry"),
          (item) => parseInt(item)
        );

        if (paramsIndustryIds.length) {
          const industryFromStorage = uiFilterData.getSelectedFilter(
            recentIndustry,
            paramsIndustryIds
          );

          setSelectedIndustry(industryFromStorage[0]);

          Object.assign(params, {
            industry: industryFromStorage[0]?.value,
          });
        } else {
          uiFilterData.remove(LOCALSTORAGE_FILTER_DATA_KEY.industry);
        }
      }
    }

    if (selectedPriority) {
      Object.assign(params, {
        priority: selectedPriority.value,
      });
    } else {
      const recentPriority = uiFilterData.get(
        LOCALSTORAGE_FILTER_DATA_KEY.priority
      );
      if (!recentPriority) {
        searchParams.delete("priority");
      } else {
        const paramsPriorityIds = _.map(
          searchParams.getAll("priority"),
          (item) => parseInt(item)
        );

        if (paramsPriorityIds.length) {
          const priorityFromStorage = uiFilterData.getSelectedFilter(
            recentPriority,
            paramsPriorityIds
          );

          setSelectedPriority(priorityFromStorage[0]);

          Object.assign(params, {
            priority: priorityFromStorage[0]?.value,
          });
        } else {
          uiFilterData.remove(LOCALSTORAGE_FILTER_DATA_KEY.priority);
        }
      }
    }

    if (selectedUser) {
      Object.assign(params, {
        user: selectedUser.value,
      });
    } else {
      const recentUser = uiFilterData.get(LOCALSTORAGE_FILTER_DATA_KEY.user);
      if (!recentUser) {
        searchParams.delete("user");
      } else {
        const paramsUserIds = _.map(searchParams.getAll("user"), (item) =>
          parseInt(item)
        );

        if (paramsUserIds.length) {
          const userFromStorage = uiFilterData.getSelectedFilter(
            recentUser,
            paramsUserIds
          );

          setSelectedUser(userFromStorage[0]);

          Object.assign(params, {
            user: userFromStorage[0]?.value,
          });
        } else {
          uiFilterData.remove(LOCALSTORAGE_FILTER_DATA_KEY.user);
        }
      }
    }

    if (selectedStatus) {
      Object.assign(params, {
        status: selectedStatus.value,
      });
    } else {
      const recentStatus = uiFilterData.get(
        LOCALSTORAGE_FILTER_DATA_KEY.status
      );
      if (!recentStatus) {
        searchParams.delete("status");
      } else {
        const paramsStatusIds = _.map(searchParams.getAll("status"), (item) =>
          parseInt(item)
        );

        if (paramsStatusIds.length) {
          const statusFromStorage = uiFilterData.getSelectedFilter(
            recentStatus,
            paramsStatusIds
          );

          setSelectedStatus(statusFromStorage[0]);

          Object.assign(params, {
            status: statusFromStorage[0]?.value,
          });
        } else {
          uiFilterData.remove(LOCALSTORAGE_FILTER_DATA_KEY.status);
        }
      }
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("/lead");
    }

    const tableParams: IPaginationParams = {
      page: params.page,
      per_page: tableQuery.per_page,
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      ...(tableQuery.order_by
        ? { order_by: tableQuery.order_by }
        : { order_by: "created_at" }),
      ...(tableQuery.sorted_by
        ? { sorted_by: overrideTableSortOrder(tableQuery.sorted_by) }
        : { sorted_by: "DESC" }),
      ...(params.industry ? { industry: params.industry } : {}),
      ...(params.priority ? { priority: params.priority } : {}),
      ...(params.user ? { user: params.user } : {}),
      ...(params.status ? { status: params.status } : {}),
    };

    await loadData(tableParams);

    setHasInitialize(true);
  }, [
    tableQuery,
    debouncedSearch,
    selectedIndustry,
    selectedPriority,
    selectedUser,
    selectedStatus,
    loadData,
    navigate,
    searchParams,
    setSearchParams,
  ]);

  const columns: ColumnsType<LeadDataList> = [
    {
      title: t("common.text.date"),
      dataIndex: "date",
      key: "date",
      sorter: true,
      width: 100,
      defaultSortOrder: tableQuery.order_by === "date" && tableQuery.sorted_by,
      render: (date) => moment(date).format("DD MMM YYYY"),
    },
    {
      title: t("common.text.client"),
      dataIndex: "customer_name",
      key: "customer_name",
      width: 180,
      sorter: true,
    },
    {
      title: t("common.text.assignee"),
      dataIndex: "assign_name",
      key: "assign_name",
      width: 180,
      sorter: true,
      defaultSortOrder:
        tableQuery.order_by === "assign_name" && tableQuery.sorted_by,
    },
    {
      title: t("common.text.lastUpdate"),
      dataIndex: "last_activity_date",
      key: "last_activity_date",
      width: 150,
      sorter: true,
      defaultSortOrder:
        tableQuery.order_by === "last_update_date" && tableQuery.sorted_by,
      render: (date) => moment(date).fromNow(),
    },
    {
      title: t("common.text.lastNote"),
      dataIndex: "last_activity_note",
      key: "last_activity_note",
      width: 150,
      sorter: true,
      defaultSortOrder:
        tableQuery.order_by === "last_update_note" && tableQuery.sorted_by,
      render: (text) => text ?? "-",
    },
    {
      title: t("common.text.status"),
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (text) => (
        <Tag color={_.get(LEAD_STATUS_COLOR, text, "blue")}>
          {snakeCaseToTitleCase(text)}
        </Tag>
      ),
    },
    {
      title: t("common.text.action"),
      key: "id",
      className: "table-action",
      fixed: "right",
      width: 110,
      render: (text, record) => {
        return (
          <VuiTableActionButton
            detailLink={`/lead/${record.id}`}
            detailPermissionName="lead.show"
            showDetail={record.can_show}
            showDelete={record.can_delete}
          />
        );
      },
    },
  ];

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      const params: IPaginationParams = {
        page: pagination.current,
        per_page: pagination.pageSize,
      };

      if (sorter.order) {
        Object.assign(params, {
          sorted_by: sorter.order,
          order_by: sorter.field,
        });
      } else {
        Object.assign(params, {
          sorted_by: null,
          order_by: null,
        });
      }

      setTableQuery(params);
    },
    []
  );

  const handleOnChangeIndustry = useCallback((value: ValueType) => {
    uiFilterData.save(LOCALSTORAGE_FILTER_DATA_KEY.industry, [value]);
    setSelectedIndustry(value);
    setTableQuery((prevState) => ({
      ...prevState,
      page: 1,
      industry: value,
    }));
  }, []);

  const handleOnChangeUser = useCallback((value: ValueType) => {
    uiFilterData.save(LOCALSTORAGE_FILTER_DATA_KEY.user, [value]);
    setSelectedUser(value);
    setTableQuery((prevState) => ({
      ...prevState,
      page: 1,
      user: value,
    }));
  }, []);

  const handleOnChangePriority = useCallback((value: ValueType) => {
    uiFilterData.save(LOCALSTORAGE_FILTER_DATA_KEY.priority, [value]);
    setSelectedPriority(value);
    setTableQuery((prevState) => ({
      ...prevState,
      page: 1,
      priority: value,
    }));
  }, []);

  const handleOnChangeStatus = useCallback((value: ValueType) => {
    uiFilterData.save(LOCALSTORAGE_FILTER_DATA_KEY.status, [value]);
    setSelectedStatus(value);
    setTableQuery((prevState) => ({
      ...prevState,
      page: 1,
      status: value,
    }));
  }, []);

  useEffect(() => {
    (async () => {
      await handleChangeSearchParams();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableQuery]);

  useEffect(() => {
    if (hasInitialize) {
      setTableQuery((prevState) => ({
        ...prevState,
        search: debouncedSearch,
        page: 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <>
      <VuiPageTitle title={t("pages.lead.list.title")}>
        <VuiAccessible access="lead.store">
          <Link to="/lead/add">
            <VuiButton
              title={t("common.button.addItem", {
                item: t("common.text.lead"),
              })}
              buttonProps={{
                icon: <PlusOutlined />,
                size: "middle",
              }}
            />
          </Link>
        </VuiAccessible>
      </VuiPageTitle>

      <Row gutter={[16, 16]} className="mb16">
        <Col className="gutter-row" xs={24} md={8}>
          <Input
            allowClear
            placeholder={t("common.form.search.placeholder")}
            prefix={<SearchOutlined />}
            value={search}
            size="middle"
            onChange={(value) => {
              setSearch(value.target.value);
            }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mb16">
        <Col className="gutter-row" xs={24} md={6}>
          <VuiSelect
            repository={IndustryRepository}
            value={selectedIndustry}
            placeholder={t("common.text.selectIndustry")}
            onChange={handleOnChangeIndustry}
            className="select-filter"
            size="middle"
          />
        </Col>
        <Col className="gutter-row" xs={24} md={6}>
          <VuiSelect
            repository={UserRepository}
            value={selectedUser}
            placeholder={t("common.text.selectAssignee")}
            onChange={handleOnChangeUser}
            className="select-filter"
            size="middle"
          />
        </Col>
        <Col className="gutter-row" xs={24} md={6}>
          <VuiSelect
            repository={ConstantRepository}
            value={selectedPriority}
            placeholder={t("common.text.selectPriority")}
            onChange={handleOnChangePriority}
            repositoryParams={{
              for: "priority_lead",
            }}
            labelKey="label"
            className="select-filter"
            size="middle"
          />
        </Col>
        <Col className="gutter-row" xs={24} md={4}>
          <VuiSelect
            defaultOptions={leadStatusOptions(t)}
            value={selectedStatus}
            placeholder={t("common.text.selectStatus")}
            onChange={handleOnChangeStatus}
            className="select-filter"
            size="middle"
          />
        </Col>
      </Row>

      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={data}
        loading={isOnFetchingData}
        onChange={handleTableChange}
        pagination={{
          current: tableQuery.page,
          showSizeChanger: true,
          pageSize: tableQuery.per_page,
          total: totalData,
        }}
        size="small"
      />
    </>
  );
};

export default LeadListModule;
